import React from "react"
import { Link, graphql } from "gatsby"
import { Col, Image, Row } from "antd"
import defaultImage from "../../../static/default.jpg"

import { CustomLayout, Description } from "../../components"
import "../../styles/dashboard.scss"
const dashboard = ({ pageContext: { categoryName }, data }) => {
  const { company_name, logo, iso_code, categoryList } = data?.file
    ?.childDataJson || {
    company_name: "",
    logo: null,
    iso_code: null,
    categoryList: [],
  }
  return (
    <CustomLayout
      isDashboard
      companyName={company_name}
      logo={logo ? `${process.env.GATSBY_IMAGE_SERVER_URL}${logo}` : null}
      lang={iso_code?.toLowerCase()}
    >
      <div>
        <Row justify="space-around" className="card__container">
          {categoryList.map(
            ({ Name, ImageName, Header: description }, index) => (
              <Col
                xs={{ span: 11 }}
                md={7}
                xl={5}
                style={{ marginBottom: "2vh" }}
              >
                <Link to={`/category${index}`}>
                  <div className="d-card">
                    <Image
                      className="d-card__image"
                      width={"100%"}
                      src={
                        ImageName
                          ? `${process.env.GATSBY_IMAGE_SERVER_URL}${ImageName}`
                          : defaultImage
                      }
                      alt={Name}
                      preview={false}
                      placeholder={
                        <Image
                          className="d-card__image"
                          width={"100%"}
                          src={
                            ImageName
                              ? `${
                                  process.env.GATSBY_IMAGE_SERVER_URL
                                }${ImageName?.replace(
                                  ".png",
                                  "-blured-image.png"
                                )}`
                              : defaultImage
                          }
                          alt={Name}
                          preview={false}
                        />
                      }
                    />
                    <div className="d-card__meta">
                      <span className="meta__title">{Name}</span>
                      <span className="meta__desc">
                        <Description dirty={description} />
                      </span>
                    </div>
                  </div>
                </Link>
              </Col>
            )
          )}
        </Row>
      </div>
    </CustomLayout>
  )
}

export const query = graphql`
  query Dashboard1 {
    file(relativePath: { eq: "common-Info.json" }) {
      childDataJson {
        logo
        company_name
        iso_code
        categoryList {
          Name
          ImageName
          Header
        }
      }
    }
  }
`

export default dashboard
